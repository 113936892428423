import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControlLabel,
	Checkbox,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	FormHelperText,
	Tooltip
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteUser,
	getAIModels,
	getUserById,
	postUser,    
	updateUser,
} from '../../../../api/user';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { ArrowUpward, ArrowDownward, Visibility } from '@material-ui/icons'
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import { SERVER_URL } from "../../../../api/index"
import DeleteIcon from '@material-ui/icons/Delete'
import { buttonsStyle } from '../../../components/tables/table'
import Editor from '../../../components/editor/Editor'
import { useSelector, shallowEqual } from "react-redux";
import { getNonEmpty } from '../../../../utils/helpers';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};


function getEmptyStudent() {

	return {
		fullName: '',
		email: '',
		role: 'student',
		phone: '',
		birthdate: null,
		imagesURLs: [],
		priceHour: '',
		skills: '',
		password: '',
		repeatPassword: '',
		certifications: [],
		specializations: [],
		status: 'active'
	};
}

export default function EditStudentsPage() {
	const [student, setStudent] = useState(getEmptyStudent());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [selectedImages, setSelectedImages] = useState(null)
	const [deletedImages, setDeletedImages] = useState(null)
	const [openPreviewDialog, setOpenPreviewDialog] = useState(null)
	const [newPassword, setNewPassword] = useState({ password: null, repeatPassword: null })
	const [changePassword, setChangePassword] = useState(false)
	const studentId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!studentId) {
			disableLoadingData();
			return;
		}
		getUserById(studentId)
			.then((res) => {
				if (res.status === 200) {
					const student = res.data;
					delete student.password;
					setStudent(student);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get student.' });
				history.push('/students');
			});
	}, [studentId, disableLoadingData, history]);

	function saveStudent() {
		let saveStudent = student
		if (!studentId || changePassword) {
		  if (!newPassword.password || !newPassword.repeatPassword) {
			alertError({ error: null, customMessage: 'Please enter the password.' })
			return
		  }
		  if (newPassword.password !== newPassword.repeatPassword) {
			alertError({ error: null, customMessage: 'Passwords do not match.' })
			return
		  }
		  saveStudent = {...saveStudent, password: newPassword.password }
		}
		if (typeof saveStudent.priceHour === 'string') {
			saveStudent.priceHour = saveStudent.priceHour.includes(',') ? saveStudent.priceHour.replace(',', '.') : saveStudent.priceHour
			if (isNaN(saveStudent.priceHour)) {
			  alertError({ error: null, customMessage: 'Price/Hour should be a number.' })
			  return
			}
			saveStudent.priceHour = parseFloat(saveStudent.priceHour)
		}
		if (!studentId) {
			postUser(saveStudent, selectedImages)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Student successfully created.',
						});
						history.push('/students');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save student.' });
				});
		} else {
			updateUser(studentId, saveStudent, selectedImages)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/students');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element, day) => (event) => {
		setStudent({ ...student, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value) => {
		setStudent({ ...student, [element]: value })
	  }


	const handleDeleteImage = (index) => {
		// TODO: DELETE IMAGES FROM SERVER
		let newImages = [...student.imagesURLs]
		const deletedImage = newImages.splice(index, 1)
		setStudent({ ...student, imagesURLs: newImages })
		if (selectedImages && selectedImages[index]) {
		  let _selectedImages = {}
		  for (const [key, value] of Object.entries(selectedImages)) {
			if (key !== index) {
			  if (key > index) {
				_selectedImages[key - 1] = value
			  } else _selectedImages[key] = value
			} 
		  }
		  if (!Object.keys(_selectedImages).length) _selectedImages = null
		  setSelectedImages(_selectedImages)
		} else setDeletedImages(deletedImages ? deletedImages.concat(deletedImage) : [deletedImage])
	  }
	
	  const handleMoveImage = (index, newIndex) => {
		const aux = student.imagesURLs[index]
		let images = [...student.imagesURLs]
		images.splice(index, 1, images[newIndex])
		images.splice(newIndex, 1, aux)
		setStudent({ ...student, imagesURLs: images })
		if (selectedImages && (selectedImages[index] || selectedImages[newIndex])) {
		  let _selectedImages = {}
		  for (const [key, value] of Object.entries(selectedImages)) {
			if (key === index.toString()) _selectedImages[newIndex] = value
			else if (key === newIndex.toString()) _selectedImages[index] = value
			else _selectedImages[key] = value
		  }
		  setSelectedImages(_selectedImages)
		}
	  }

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit student'></CardHeader>
					<CardBody>
						<TextField
							id={`fullName`}
							label='Full name'
							value={student.fullName}
							onChange={handleChange('fullName')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`email`}
							label='Email'
							value={student.email}
							onChange={handleChange('email')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<br />
						<br />
						<TextField
							id={`phone`}
							label="Phone"
							value={student.phone}
							onChange={handleChange('phone')}
							InputLabelProps={{
								shrink: true
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<br/>
						<Editor
							body={student.skills || ''}
							setBody={new_body => handleChangeEditor('skills', new_body)}
							className='max-height'
							placeholder={'Enter skills description here...'}
							label="Skills"
						/>
						<br/>
						{(!studentId || changePassword) ? <>
							<br />
							<br />
							<TextField
								id={`password`}
								label="Password"
								value={newPassword.password}
								onChange={(event) => {
									if (event.target.value !== ' ') setNewPassword({ ...newPassword, password: event.target.value })
								}}
								InputLabelProps={{
									shrink: true
								}}
								type="password"
								margin="normal"
								variant="outlined"
								required
							/>
							<TextField
								id={`repeatPassword`}
								label="Repeat password"
								value={newPassword.repeatPassword}
								onChange={(event) => {
									if (event.target.value !== ' ') setNewPassword({ ...newPassword, repeatPassword: event.target.value })
								}}
								InputLabelProps={{
									shrink: true
								}}
								type="password"
								margin="normal"
								variant="outlined"
								required
							/>
							<br/>
							<br/>
							{studentId && <>
								<Button
									onClick={() => {
										setChangePassword(false)
										setNewPassword({ password: null, repeatPassword: null })
									}}
									variant="outlined"
									style={{ marginRight: '20px' }}
								>
									Cancel change password
								</Button>
								<br />
								<br />  
							</>}
							</> : <>
							<br />
							<br />
							<Button
								onClick={() => setChangePassword(true)}
								variant="outlined"
								color="primary"
								style={{ marginRight: '20px' }}>
								Change password
							</Button>
							<br />
							<br />
						</>}
						{student.imagesURLs?.map((_, index) => {
							return (
							<>
								<label htmlFor={"upload-image" + index}>
									<input
										style={{ display: 'none' }}
										id={"upload-image" + index}
										name={"upload-image" + index}
										type="file"
										accept={'image/*'}
										onChange={(e) => {
											setSelectedImages({ ...selectedImages, [index]: e.target.files[0] })
										}}
									/>
									<Button
										style={{ marginRight: '15px' }}
										color="secondary"
										component="span"
										variant="outlined">
										{((selectedImages && selectedImages[index]) || student.imagesURLs[index] !== '') ? 'Change image ' + (index + 1) : 'Upload image ' + (index + 1)}
									</Button>
								</label>
								{((selectedImages && selectedImages[index]) || student.imagesURLs[index] !== '') &&
								<>
									<Tooltip title={'Preview image ' + (index + 1)}>
										<Button
											size="small"
											onClick={() => setOpenPreviewDialog(index)}
											style={buttonsStyle}>
											<Visibility/>
										</Button>
									</Tooltip>
									<PreviewDialog
										title={'Preview image ' + (index + 1)}
										open={openPreviewDialog === index}
										setOpen={setOpenPreviewDialog}
										src={(selectedImages && selectedImages[index]) ? URL.createObjectURL(selectedImages[index]) : `${SERVER_URL}/${student.imagesURLs[index]}`}
									/>
									<Tooltip title="Delete">
										<Button
											size="small"
											style={buttonsStyle}
											onClick={() => handleDeleteImage(index)}>
											<DeleteIcon/>
										</Button>
									</Tooltip>
									<Tooltip title="Move up">
										<Button
											size="small"
											style={buttonsStyle}
											disabled={index === 0}
											onClick={() => handleMoveImage(index, index - 1)}>
											<ArrowUpward/>
										</Button>
									</Tooltip>
									<Tooltip title="Move down">
										<Button
											size="small"
											disabled={!(index < student.imagesURLs.length - 1 && ((selectedImages && selectedImages[index + 1]) || student.imagesURLs[index + 1] !== ''))}
											style={{...buttonsStyle, marginRight: '1em' }}
											onClick={() => handleMoveImage(index, index + 1)}>
											<ArrowDownward/>
										</Button>
									</Tooltip>
									<span>
										{selectedImages && selectedImages[index] ? selectedImages[index]?.name : (student.imagesURLs[index] !== '' ? student.imagesURLs[index].split(/-(.*)/s)[1] : '')}
									</span>
									<br />
								</>
								}
							</>
							)
						})}
						<br />
						<br />
						<Button
							variant="outlined"
							color="primary"
							onClick={() => setStudent({ ...student, imagesURLs: student.imagesURLs.concat('') })}
						>
							Add image
						</Button>
						<br/>
						<br/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/students')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveStudent()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save student
					</Button>
					{studentId && user?.role.includes('admin') && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete student
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this student?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteUser(studentId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Student deleted successfully',
												});
												history.push('/students');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete student.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
