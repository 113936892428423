import { authClient, API } from '../index'

// Get all schools
export const getSchools = () => {
  return authClient().get(`${API}/school`)
}

// Get school by id
export const getSchoolById = (id) => {
  return authClient().get(`${API}/school/${id}`)
}

// Delete school
export const deleteSchool = (id) => {
  return authClient().delete(`${API}/school/${id}`)
}

// Create school
export const postSchool = (school) => {
  return authClient().post(`${API}/school`, school)
}

// Update school
export const updateSchool = (id, school) => {
  return authClient().put(`${API}/school/${id}`, school)
}
