import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteSchool, getSchoolById, postSchool, updateSchool } from '../../../../api/school'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

function getEmptySchool() {
  return {
    fullName: ''
  }
}

export default function EditSchoolsPage() {
  const [school, setSchool] = useState(getEmptySchool())
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const schoolId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!schoolId) {
      disableLoadingData()
      return
    }
    getSchoolById(schoolId).then(res => {
      if (res.status === 200) {
        setSchool(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get school.' })
      history.push('/schools')
    })
  }, [schoolId, disableLoadingData, history])

  function saveSchool() {
    if (!schoolId) {
      postSchool(school).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'School successfully created.' })
          history.push('/schools')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save school.' })
      })
    } else {
      updateSchool(schoolId, school).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/schools')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setSchool({ ...school, [element]: event.target.value })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit school'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`fullName`}
            label="Full name"
            value={school.fullName}
            onChange={handleChange('fullName')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/schools')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveSchool()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save school
      </Button>
      <MuiThemeProvider theme={theme}>
        <Button
          onClick={() => setOpenConfirmDialog(true)}
          variant="outlined"
          color="secondary">
          Delete school
        </Button>
      </MuiThemeProvider>

      <ConfirmDialog
        title={'Are you sure you want to delete this school?'}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          deleteSchool(schoolId).then(res => {
            if (res.status === 204 || res.status === 200) {
              alertSuccess({ title: 'Deleted!', customMessage: 'School deleted successfully' })
              history.push('/schools')
            }
          }).catch(error => {
            alertError({ error: error, customMessage: 'Could not delete economic sector.' })
          })
        }}
      />
    </>
  );
}
