import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";
import AdminsPage from "./modules/ContentManager/admins/AdminsPage";
import EditAdminsPage from "./modules/ContentManager/admins/EditAdminsPage";
import ClinicCasesPage from "./modules/ContentManager/cliniccases/ClinicCasesPage";
import EditGamesPage from "./modules/ContentManager/games/EditGamesPage";
import GamesPage from "./modules/ContentManager/games/GamesPage";
import RankingGamesPage from "./modules/ContentManager/games/RankingGamesPage";
import EditGroupsPage from "./modules/ContentManager/groups/EditGroupsPage";
import GroupsPage from "./modules/ContentManager/groups/GroupsPage";
import QuizzesPage from "./modules/ContentManager/quizzes/QuizzesPage";
import ViewQuizPage from "./modules/ContentManager/quizzes/ViewQuizPage";
import ScenesPage from "./modules/ContentManager/scenes/ScenesPage";
import ViewScenePage from "./modules/ContentManager/scenes/ViewScenePage";
import EditSurveysPage from "./modules/ContentManager/surveys/EditSurveysPage";
import SurveysPage from "./modules/ContentManager/surveys/SurveysPage";
import { DashboardPage } from "./pages/DashboardPage";
import { ResultsPage } from "./pages/ResultsPage";
import EditLanguagesPage from "./modules/ContentManager/languages/EditLanguagesPage";
import LanguagesPage from "./modules/ContentManager/languages/LanguagesPage";
import EditTextsPage from "./modules/ContentManager/texts/EditTextsPage";
import TextsPage from "./modules/ContentManager/texts/TextsPage";
import EditSchoolsPage from "./modules/ContentManager/schools/EditSchoolsPage";
import SchoolsPage from "./modules/ContentManager/schools/SchoolsPage";
import EditTeachersPage from "./modules/ContentManager/teachers/EditTeachersPage";
import TeachersPage from "./modules/ContentManager/teachers/TeachersPage";
import EditStudentsPage from "./modules/ContentManager/students/EditStudentsPage";
import StudentsPage from "./modules/ContentManager/students/StudentsPage";

export default function BasePage() {
	return (
		<Switch>
			{/* Redirect from root URL to /dashboard. */}
			<Redirect exact from="/" to="/dashboard" />
			<ContentRoute path="/dashboard" component={DashboardPage} />
			<ContentRoute path="/results" component={ResultsPage} />
			{/* Administrators */}
			<ContentRoute from="/admins" component={AdminsPage} />
			<ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />
			{/* Groups */}
			<ContentRoute from="/groups" component={GroupsPage} />
			<ContentRoute from="/edit-group/:id?" component={EditGroupsPage} />
			{/* Games */}
			<ContentRoute from="/games" component={GamesPage} />
			<ContentRoute from="/edit-game/:id?" component={EditGamesPage} />
			<ContentRoute
				from="/ranking-game/:id?"
				component={RankingGamesPage}
			/>
			{/* Clinical cases */}
			<ContentRoute from="/cliniccases" component={ClinicCasesPage} />
			{/* Scenes */}
			<ContentRoute from="/scenarios" component={ScenesPage} />
			<ContentRoute from="/scenario/:id" component={ViewScenePage} />
			{/* Quizzes */}
			<ContentRoute from="/quizzes" component={QuizzesPage} />
			<ContentRoute from="/quiz/:id" component={ViewQuizPage} />
			{/* Surveys */}
			<ContentRoute from="/surveys" component={SurveysPage} />
			<ContentRoute
				from="/edit-survey/:id?"
				component={EditSurveysPage}
			/>
			{/* Languages */}
			<ContentRoute
				from="/languages"
				component={LanguagesPage}
			/>
			<ContentRoute
				from="/edit-language/:id?"
				component={EditLanguagesPage}
			/>
			{/* Texts */}
			<ContentRoute
				from="/texts"
				component={TextsPage}
			/>
			<ContentRoute
				from="/edit-text/:id?"
				component={EditTextsPage}
			/>
			{/* Schools */}
			<ContentRoute
				from="/schools"
				component={SchoolsPage}
			/>
			<ContentRoute
				from="/edit-school/:id?"
				component={EditSchoolsPage}
			/>
			{/* Teachers */}
			<ContentRoute
				from="/teachers"
				component={TeachersPage}
			/>
			<ContentRoute
				from="/edit-teacher/:id?"
				component={EditTeachersPage}
			/>
			{/* Students */}
			<ContentRoute
				from="/students"
				component={StudentsPage}
			/>
			<ContentRoute
				from="/edit-student/:id?"
				component={EditStudentsPage}
			/>
			<Redirect to="/error" />
		</Switch>
	);
}
