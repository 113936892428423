import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle,
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getTexts, deleteText, changeStatusText } from '../../../../api/text';
import { Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { shallowEqual, useSelector } from 'react-redux';
import { getNonEmpty } from '../../../../utils/helpers';

function getData(texts) {
	let data = [];
	for (let i = 0; i < texts.length; ++i) {
		const elem = {};
		elem.title = getNonEmpty(texts[i].title);
		elem.createdAt = texts[i].createdAt;
		elem.publication_date = texts[i].publication_date;
		elem.id = texts[i]._id;
		elem.active = texts[i].active;
		data = data.concat(elem);
	}
	return data;
}

export default function TextsPage() {
	const [data, setData] = useState([]);
	const [textId, setTextId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title='Edit'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-text/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes('admin') && (
					<>
						<Tooltip title={elem?.active ? 'Disable' : 'Enable'}>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setTextId(elem);
									setOpenConfirmDialog(1);
								}}>
								{elem?.active ? <ToggleOffIcon /> : <ToggleOnIcon style={{ color: 'red' }} />}
							</Button>
						</Tooltip>
						<Tooltip title='Delete'>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setTextId(cell);
									setOpenConfirmDialog(2);
								}}>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{ dataField: 'title', text: 'Title', sort: true },
		{
			dataField: 'publication_date',
			text: 'Publication date',
			formatter: (value) => dateFormatter(value, true),
			sort: true,
		},
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getTexts()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get texts.' });
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Texts list'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-text')}>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							textId?.active ? 'disable' : 'enable'
						} this text?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusText(textId.id, !textId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${textId?.active ? 'Disabled!' : 'Enabled!'}`,
											customMessage: `Text ${
												textId?.active ? 'disabled' : 'enabled'
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											textId?.active ? 'disable' : 'enable'
										} text.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={'Are you sure you want to remove this text?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteText(textId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Text removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove text.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
