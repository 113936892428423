/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";
import { Receipt, School, AssignmentInd, Poll, AccountBalance, People, ListAlt, Description, Language} from "@material-ui/icons";

export function AsideMenuList({ layoutProps }) {
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const isModerator = user.role === "moderator" ? true : false;
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? `${!hasSubmenu ? "menu-item-active" : ""} menu-item-open menu-item-not-hightlighted`
			: "";
	};

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive(
						"/dashboard",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/dashboard">
						<span className="svg-icon menu-icon">
							<SVG
								src={toAbsoluteUrl(
									"/media/svg/icons/Design/Layers.svg"
								)}
							/>
						</span>
						<span className="menu-text">Dashboard</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">STUDENTS MANAGEMENT</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/students",
						false
					)} ${getMenuItemActive("/edit-student", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/students">
						<span className="menu-icon">
							<People />
						</span>
						<span className="menu-text">
							Students
						</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">TEACHERS MANAGEMENT</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/schools",
						false
					)} ${getMenuItemActive("/edit-school", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/schools">
						<span className="menu-icon">
							<AccountBalance />
						</span>
						<span className="menu-text">
							Schools
						</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/teachers",
						false
					)} ${getMenuItemActive("/edit-teacher", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/teachers">
						<span className="menu-icon">
							<School />
						</span>
						<span className="menu-text">
							Teachers
						</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">EXERCISES DATABASE</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/texts",
						false
					)} ${getMenuItemActive("/edit-text", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/texts">
						<span className="menu-icon">
							<Description />
						</span>
						<span className="menu-text">
							Texts
						</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/exercises",
						false
					)} ${getMenuItemActive("/edit-execise", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/exercises">
						<span className="menu-icon">
							<ListAlt />
						</span>
						<span className="menu-text">
							Exercises
						</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">MANAGEMENT</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/surveys",
						false
					)} ${getMenuItemActive("/edit-survey", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/surveys">
						<span className="menu-icon">
							<Poll />
						</span>
						<span className="menu-text">
							Satisfaction survey
						</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/languages',
						false
					)} ${getMenuItemActive('/edit-language', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/languages'>
						<span className='menu-icon'>
							<Language />
						</span>
						<span className='menu-text'>Languages</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/admins",
						false
					)} ${getMenuItemActive("/edit-admin", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/admins">
						<span className="menu-icon">
							<AssignmentInd />
						</span>
						<span className="menu-text">
							Administrators
						</span>
					</NavLink>
				</li>
			</ul>

			{/* end::Menu Nav */}
		</>
	);
}
