import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	Tooltip,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { deleteText, getTextById, postText, updateText } from '../../../../api/text';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import Editor from '../../../components/editor/Editor';
import { shallowEqual, useSelector } from 'react-redux';
import { Visibility } from '@material-ui/icons';
import PreviewDialog from '../../../components/dialogs/PreviewDialog';
import { SERVER_URL } from '../../../../api/index';
import { buttonsStyle } from '../../../components/tables/table';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import { checkIsEmpty } from '../../../../utils/helpers';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyText() {
	return {
		title: {},
		description: {},
		summary: {},
		imageURL: '',
		videoURL: '',
		fileURL: '',
		link: '',
		publication_date: (new Date()).toISOString(),
		active: true,
	};
}

export default function EditTextsPage() {
	const [text, setText] = useState(getEmptyText());
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [openPreviewFile, setOpenPreviewFile] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const textId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!textId) {
			disableLoadingData();
			return;
		}
		getTextById(textId)
			.then((res) => {
				if (res.status === 200) {
					setText(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get text.' });
				history.push('/texts');
			});
	}, [textId, disableLoadingData, history]);

	function saveText() {
		if (
			checkIsEmpty(text.title) ||
			checkIsEmpty(text.description) ||
			checkIsEmpty(text.summary) ||
			!text.publication_date
		)
			return alertError({
				error: null,
				customMessage:
					'The title, description, summary and publication date are required in at least one of the languages.',
			});
		if (text.link) {
			try {
				if (!Boolean(new URL(text.link)))
					return alertError({
						error: null,
						customMessage: 'The link is not valid.',
					});
			} catch (error) {
				return alertError({
					error: null,
					customMessage: 'The link is not valid.',
				});
			}
		}

		if (!textId) {
			postText(text, selectedImage, selectedFile)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Text successfully created.',
						});
						history.push('/texts');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save text.' });
				});
		} else {
			updateText(textId, text, selectedImage, selectedFile)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/texts');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return;
		if (lang) {
			if (!text[element]) text[element] = {};
			let newText = text[element];
			newText[lang] = event.target.value;
			setText({ ...text, [element]: newText });
		} else setText({ ...text, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value === ' ') return;
		if (lang) {
			if (!text[element]) text[element] = {};
			let newText = text[element];
			newText[lang] = value;
			setText({ ...text, [element]: newText });
		} else setText({ ...text, [element]: value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label='Title'
					value={(text.title && text.title[lang]) || ''}
					onChange={handleChange('title', lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin='normal'
					variant='outlined'
					required
				/>
				<br />
				<Editor
					body={(text.description && text.description[lang]) || ''}
					setBody={(new_body) =>
						handleChangeEditor('description', new_body, lang)
					}
					className='max-height'
					placeholder={'Enter text description here...'}
					label='Description *'
				/>
				<br />
				<Editor
					body={(text.summary && text.summary[lang]) || ''}
					setBody={(new_body) => handleChangeEditor('summary', new_body, lang)}
					className='max-height'
					placeholder={'Enter text summary here...'}
					label='Summary *'
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit text'></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={renderMultilanguageTabContent}
						/>
						<br/>
						<TextField
							id="publication_date"
							label="Publication date (España):"
							type="date"
							value={text.publication_date && text.publication_date?.includes('T') ? text.publication_date.split('T')[0] : text.publication_date}
							onChange={handleChange('publication_date')}
							InputLabelProps={{
								shrink: true
							}}
							margin="normal"
							variant="outlined"
						/>
						<br />
						<TextField
							id={`videoURL`}
							label='Youtube Video Id'
							value={text.videoURL || ''}
							onChange={handleChange('videoURL')}
							InputLabelProps={{
								shrink: true,
							}}
							type='url'
							margin='normal'
							variant='outlined'
						/>
						<br />
						<TextField
							id={`link`}
							label='Link URL'
							value={text.link || ''}
							onChange={handleChange('link')}
							InputLabelProps={{
								shrink: true,
							}}
							type='url'
							margin='normal'
							variant='outlined'
						/>
						<br/>
						<br/>
						<label htmlFor={'upload-image'}>
							<input
								style={{ display: 'none' }}
								id={'upload-image'}
								name={'upload-image'}
								type='file'
								accept={'image/*'}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color='secondary'
								component='span'
								variant='outlined'>
								{selectedImage || text.imageURL !== ''
									? 'Change image'
									: 'Upload image'}
							</Button>
						</label>
						{(selectedImage || text.imageURL !== '') && (
							<>
								<Tooltip title={'Preview image'}>
									<Button
										size='small'
										onClick={() => setOpenPreviewDialog(true)}
										style={{ ...buttonsStyle, marginRight: '15px' }}>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Preview image'}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${text.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: text.imageURL !== ''
										? text.imageURL.split(/-(.*)/s)[1]
										: ''}
								</span>
							</>
						)}
						<br />
						<br />
						<label htmlFor={'upload-file'}>
							<input
								style={{ display: 'none' }}
								id={'upload-file'}
								name={'upload-file'}
								type='file'
								accept="application/pdf"
								onChange={(e) => {
									setSelectedFile(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color='secondary'
								component='span'
								variant='outlined'>
								{selectedFile || text.fileURL !== ''
									? 'Change file'
									: 'Upload file'}
							</Button>
						</label>
						{(selectedFile || (text.fileURL && text.fileURL !== '')) && (
							<>
								<Tooltip title={'Preview file'}>
									<Button
										size='small'
										onClick={() => setOpenPreviewFile(true)}
										style={{ ...buttonsStyle, marginRight: '15px' }}>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Preview file'}
									open={openPreviewFile}
									setOpen={setOpenPreviewFile}
									src={
										selectedFile
											? URL.createObjectURL(selectedFile)
											: `${SERVER_URL}/${text.fileURL}`
									}
								/>
								<span>
									{selectedFile
										? selectedFile?.name
										: text.fileURL !== ''
										? text.fileURL.split(/-(.*)/s)[1]
										: ''}
								</span>
							</>
						)}
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={text.active}
									onChange={() => setText({ ...text, active: !text.active })}
									name='checkActive'
								/>
							}
							label='Active'
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/texts')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveText()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save text
					</Button>
					{textId && user?.role.includes('admin') && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete text
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this text?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteText(textId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Text deleted successfully',
												});
												history.push('/texts');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete text.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
