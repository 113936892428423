import { API, authClient } from '../index';
import { uploadSingleFile } from "../files"

// Get all texts
export const getTexts = () => {
	return authClient().get(`${API}/text?getDisabled=true`);
};

// Get text by id
export const getTextById = (id) => {
	return authClient().get(`${API}/text/${id}`);
};

// Delete text
export const deleteText = (id) => {
	return authClient().delete(`${API}/text/${id}`);
};

// Create text 
export const postText = async (text, image, file) => {
	if (image) {
		const response = await uploadSingleFile(image, 'texts')
		text.imageURL = response.data.fileURL
	}
	if (file) {
		const response = await uploadSingleFile(file, 'texts');
		text.fileURL = response.data.fileURL;
	}
	return authClient().post(`${API}/text`, text);
};

// Update text
export const updateText = async (id, text, image, file) => {
	if (image) {
		const response = await uploadSingleFile(image, 'texts')
		text.imageURL = response.data.fileURL
	}
	if (file) {
		const response = await uploadSingleFile(file, 'texts');
		text.fileURL = response.data.fileURL;
	}
	return authClient().put(`${API}/text/${id}`, text);
};

// Change status text
export const changeStatusText = async (id, active) => {
	return authClient().put(`${API}/text/change-status/${id}`, { active });
};