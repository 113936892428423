import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getUsers, deleteUser, getUserById, changeStatusUser } from '../../../../api/user';
import { Button, Tooltip, FormControl, InputLabel, Select, MenuItem, Switch, FormControlLabel  } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from "react-redux";
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import FiltersCard from '../../../components/filters/Filter';
import { getNonEmpty } from '../../../../utils/helpers';
import AssessmentIcon from '@material-ui/icons/Assessment'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getData(students) {
	console.log(students);
	
	let data = [];
	for (let i = 0; i < students.length; ++i) {
		if (students[i].role.includes('student')) {
			const elem = {};
			elem.name = students[i].fullName;
			elem.email = students[i].email;
			elem.createdAt = students[i].createdAt;
			elem.id = students[i]._id;
			elem.status = students[i].status;
			data = data.concat(elem);
		}
	}
	return data;
}

const initialFilters = {
	status: 'active',
	type: 'All',
	specialization: [],
	certification: []
}

export default function StudentsPage() {
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [collapsed, setCollapsed] = useState(true)
	const [filterOptions, setFilterOptions] = useState(initialFilters)
	const [student, setStudent] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	function buttonFormatter(cell) {
		const elem = data.find(item => item._id === cell)
		return (
			<>
				<Tooltip title='Edit'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-student/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes('admin') && <>
					<Tooltip title='Delete'>
						<Button
							style={buttonsStyle}
							size='small'
							onClick={() => {
								setStudent(cell);
								setOpenConfirmDialog(2);
							}}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>}
			</>
		);
	}

	function roleFormatter(cell) {
		return <>
			<span>
				{cell?.role} 
				{cell?.isAI && <span className='badge badge-primary ml-2'>
					AI
				</span>}
			</span>
		</>
	}

	const columns = [
		{ dataField: 'name', text: 'Full name', sort: true },
		{ dataField: 'email', text: 'Email', sort: true },
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		if (!user) return
		getUsers()
			.then((res) => {					
				if (res.status === 200) {
					setData(getData(res.data))
					setRefresh(false)
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get students.' });
			});
	}, [user, refresh]);

	const handleChange = (element) => (event) => {
		setFilterOptions({ ...filterOptions, [element]: event.target.value });
	}; 

	const renderFiltersContent = () => {
		return <>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.type || ''}
					onChange={handleChange('type')}
					MenuProps={MenuProps}
				>
				{['All', 'Trainer', 'Nutritionist'].map((option) => (
					<MenuItem key={option} value={option}>{option}</MenuItem>
				))}
				</Select>
			</FormControl>
		</>
	}


	return (
		<>
			<Card>
				<CardHeader title='Students list'>
					{user?.role.includes('admin') && <CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-student')}>
							Add new
						</button>
					</CardHeaderToolbar>}
				</CardHeader>
				<CardBody>
					
					<Table data={data} columns={columns} />

					<ConfirmDialog
						title={'Are you sure you want to remove this student?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteUser(student?._id)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Student removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove student.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
